
import { computed, defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { parseDate, openLinkAgriBest, notify } from "@/core/helpers/globalJaya";

export default defineComponent({
  name: "user-account",
  components: {
    Field,
    Form,
    ErrorMessage,
    CardTitleJaya,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const toggleVisibility = ref<boolean>(true);

    const submitButton1 = ref<HTMLElement | null>(null);
    const updateNameButton = ref<HTMLElement | null>(null);
    const updateStatutButton = ref<HTMLElement | null>(null);
    const updateDataPolicyButton = ref<HTMLElement | null>(null);
    const deleteAccountButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const updateNameFormDisplay = ref(false);
    const updateStatutFormDisplay = ref(false);
    const updateDataPolicyFormDisplay = ref(false);
    const updatePasswordFormDisplay = ref(false);
    const deleteAccountFormDisplay = ref(false);

    let profileDetails = computed(() => {
      return store.getters.currentUser;
    });

    let gdprPurposeStateGlobal = computed(() => {
      return store.getters.getGdprPurposeStateGlobal;
    });

    let newPurposesStateGlobal = computed(() => {
      return JSON.parse(JSON.stringify(gdprPurposeStateGlobal.value));
    });

    let gdprRecipientTypes = computed(() => {
      return store.getters.getGdprRecipientTypes;
    });

    const newRecipientPurposesStateGlobal = computed(() => {
      return gdprRecipientTypes.value.map((gdprRecipientType) => {
        const gdprPurposeRecipient = newPurposesStateGlobal.value
          .filter((el) => {
            return (
              el.purpose.rejectable &&
              el.purpose.recipient_type === gdprRecipientType.choice
            );
          })
          .map((e) => e.state);
        if (
          new Set(gdprPurposeRecipient).size === 1 &&
          gdprPurposeRecipient.length > 0 &&
          gdprPurposeRecipient[0] !== 5
        ) {
          return gdprPurposeRecipient[0];
        } else {
          return 4;
        }
      });
    });

    const isOnline = computed(() => store.getters.isOnline);

    onMounted(() => {
      setCurrentPageTitle("Mon Compte");
      store.dispatch(Actions.FETCH_GDPR_PURPOSE_STATE_GLOBAL);
    });

    const updateNameValidator = Yup.object().shape({
      first_name: Yup.string()
        .required("Le prénom est requis")
        .max(30, "Le prénom ne doit pas dépasser ${max} caractères"),
      last_name: Yup.string()
        .required("Le nom est requis")
        .max(150, "Le nom ne doit pas dépasser ${max} caractères"),
    });

    const updateStatutValidator = Yup.object().shape({
      professional_farmer: Yup.boolean().required("Le statut est requis"),
    });

    const updatePasswordValidator = Yup.object().shape({
      old_password: Yup.string().required("Le mot de passe actuel est requis"),
      password: Yup.string()
        .required("Le nouveau mot de passe est requis")
        .min(
          10,
          "Ce mot de passe est trop court. Il doit contenir au moins 10 caractères."
        )
        .matches(/[a-z]/, "Ce mot de passe doit contenir au moins 1 minuscule.")
        .matches(/[A-Z]/, "Ce mot de passe doit contenir au moins 1 majuscule.")
        .matches(/\d/, "Ce mot de passe doit contenir au moins 1 chiffre.")
        .matches(
          /[~!@#$%^&*()_+{}":;'\\[\],.\\/=+€°§¿•±¥Ø-]/,
          "Ce mot de passe doit contenir au moins 1 caractère spécial."
        ),
      password_confirm: Yup.string()
        .required("Le mot de passe de confirmation est requis")
        .oneOf(
          [Yup.ref("password"), null],
          "Les mots de passe doivent être identiques"
        ),
    });

    const deleteAccountValidator = Yup.object().shape({
      password: Yup.string().required("Le mot de passe est requis"),
      password_confirm: Yup.string()
        .required("Le mot de passe de confirmation est requis")
        .oneOf(
          [Yup.ref("password"), null],
          "Les mots de passe doivent être identiques"
        ),
    });

    const updateName = (values) => {
      if (updateNameButton.value) {
        // Activate indicator
        updateNameButton.value.setAttribute("data-kt-indicator", "on");

        store
          .dispatch(Actions.UPDATE_PROFILE, values)
          .then(() => {
            updateNameButton.value?.removeAttribute("data-kt-indicator");
            updateNameFormDisplay.value = false;
          })
          .catch(() => {
            updateNameFormDisplay.value = true;
          });
      }
    };

    const updateStatut = async (values) => {
      if (updateStatutButton.value) {
        // Activate indicator
        updateStatutButton.value.setAttribute("data-kt-indicator", "on");

        await store
          .dispatch(Actions.UPDATE_PROFILE, values)
          .then(() => {
            updateStatutFormDisplay.value = false;
          })
          .catch((error) => {
            updateStatutFormDisplay.value = true;
            if (error.redirect) {
              router.push({ name: "exploitations" });
            }
          });

        updateStatutButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateDataPolicy = async () => {
      if (updateDataPolicyButton.value) {
        // Activate indicator
        updateDataPolicyButton.value.setAttribute("data-kt-indicator", "on");

        Promise.all(
          newPurposesStateGlobal.value.map(async (el, id) => {
            if (
              el.state !==
              gdprPurposeStateGlobal.value.find((e) => e.id === el.id).state
            ) {
              const payload = {
                id: el.id,
                state: el.state,
              };
              await store.dispatch(
                Actions.UPDATE_GDPR_PURPOSE_STATE_GLOBAL,
                payload
              );
            }
          })
        ).then(() => {
          store.dispatch(Actions.FETCH_GDPR_PURPOSE_STATE_GLOBAL).then(() => {
            updateDataPolicyFormDisplay.value = false;
            notify({
              text: "Le choix de la politique de partage des données a bien été pris en compte ",
              color: "success",
              duration: 3000,
            });
          });
        });
        updateDataPolicyButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updatePassword = async (values) => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        await store
          .dispatch(Actions.CHANGE_PASSWORD, values)
          .then(() => {
            updatePasswordFormDisplay.value = false;
            router.push({ name: "sign-in" });
          })
          .catch(() => {
            updatePasswordFormDisplay.value = true;
          });

        updatePasswordButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const deleteAccount = async (values) => {
      if (deleteAccountButton.value) {
        // Activate indicator
        deleteAccountButton.value.setAttribute("data-kt-indicator", "on");

        await store
          .dispatch(Actions.DELETE_ACCOUNT, values)
          .then(() => {
            deleteAccountFormDisplay.value = false;
            router.push({ name: "sign-in" });
          })
          .catch(() => {
            deleteAccountFormDisplay.value = true;
          });

        deleteAccountButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const rightsRGPD = ref([
      {
        title: "Droit d'information",
        duotuneIcon: "communication/com012",
        url: "https://www.cnil.fr/fr/le-droit-detre-informe-sur-lutilisation-de-vos-donnees-0",
        description:
          "Vous avez le droit à une information claire et complète sur l'utilisation de vos données personnelles.",
        agribest:
          "Toutes les informations et explications sont consultables dans les conditions générales d'utilisation et " +
          "la politique de confidentialité. Vous les trouverez dans le menu de AgriBEST ci-contre.",
      },
      {
        title: "Droit d'opposition",
        duotuneIcon: "general/gen034",
        url: "https://www.cnil.fr/fr/le-droit-dopposition-refuser-lutilisation-de-vos-donnees",
        description:
          "Vous avez le droit de vous opposer à ce qu’un organisme utilise toutes ou certaines de vos données.",
        agribest:
          "Vous pouvez nous envoyer un mail pour vous opposer à l'utilisation de vos données.",
      },
      {
        title: "Droit de vérification",
        duotuneIcon: "general/gen021",
        url: "https://www.cnil.fr/fr/le-droit-dacces-connaitre-les-donnees-quun-organisme-detient-sur-vous",
        description:
          "Vous avez le droit de vérifier les données personnelles qu'un organisme détient sur vous. ",
        agribest:
          "Vous pouvez consulter toutes les données personnelles dont nous disposons sur votre espace 'Mon compte' AgriBEST.",
      },
      {
        title: "Droit de rectification",
        duotuneIcon: "general/gen055",
        url: "https://www.cnil.fr/fr/le-droit-de-rectification-corriger-vos-informations",
        description:
          "Vous avez le droit de rectifier toutes ou certaines de vos données personnelles",
        agribest:
          "Vous pouvez éditer toutes vos données personelles sur AgriBEST.",
      },
      {
        title: "Droit de suppression",
        duotuneIcon: "general/gen027",
        url: "https://www.cnil.fr/fr/le-droit-leffacement-supprimer-vos-donnees-en-ligne",
        description:
          "Vous avez le droit de supprimer une ou plusieurs de vos données personelles.",
        agribest:
          "Vous pouvez nous envoyer un mail pour supprimer vos données personelles." +
          " Vous pouvez aussi supprimer votre compte dans la section ci-dessous.",
      },
      {
        title: "Droit à la portabilité",
        duotuneIcon: "arrows/arr091",
        url: "https://www.cnil.fr/fr/le-droit-la-portabilite-obtenir-et-reutiliser-une-copie-de-vos-donnees",
        description:
          "Vous avez le droit d'emporter une copie de vos données pour les réutiliser ailleurs ! ",
        agribest:
          "Vous pouvez nous envoyer un mail, nous vous transmettrons l'ensemble de vos données.",
      },
      {
        title: "Droit au gel des données",
        duotuneIcon: "coding/cod008",
        url: "https://www.cnil.fr/fr/le-droit-la-limitation-du-traitement-geler-lutilisation-de-vos-donnees",
        description:
          "Vous avez le droit de demander à un organisme de geler temporairement l’utilisation de toutes ou certaines de vos données.",
        agribest:
          "Vous pouvez nous envoyer un mail pour geler l'utilisation de vos données sans les supprimer.",
      },
      {
        title: "Autres droits",
        duotuneIcon: "general/gen046",
        url: "https://www.cnil.fr/les-droits-pour-maitriser-vos-donnees-personnelles",
        description:
          "D'autres droits RGPD existent : le droit au déréférencement, le droit lié au profilage, le droit d'accès aux fichiers de police et aux comptes bancaires (FICOBA)",
        agribest:
          "Ces droits n'ont pas de pertinence dans le cadre de AgriBEST: aucun profilage ni référencement n'est réalisé. Vous pouvez consulter le site de la CNIL pour plus de détails.",
      },
    ]);

    const setNewGdprPurposeStateGlobal = (
      gdprRecipientTypeChoice,
      idRecipient
    ) => {
      newPurposesStateGlobal.value
        .filter((e) => {
          return (
            e.purpose.rejectable &&
            e.purpose.recipient_type === gdprRecipientTypeChoice
          );
        })
        .forEach((e) => {
          if (newRecipientPurposesStateGlobal.value[idRecipient] === 5) {
            e.state = gdprPurposeStateGlobal.value.find(
              (el) => el.id === e.id
            ).state;
          } else {
            e.state = newRecipientPurposesStateGlobal.value[idRecipient];
          }
        });
    };

    const setNewRecipientGdprPurposeStateGlobal = (
      gdprRecipientTypeChoice,
      idRecipient
    ) => {
      const gdprPurposeRecipient = newPurposesStateGlobal.value
        .filter((e) => {
          return (
            e.purpose.rejectable &&
            e.purpose.recipient_type === gdprRecipientTypeChoice
          );
        })
        .map((e) => e.state);
      if (
        new Set(gdprPurposeRecipient).size === 1 &&
        gdprPurposeRecipient.length > 0 &&
        gdprPurposeRecipient[0] !== 5
      ) {
        newRecipientPurposesStateGlobal.value[idRecipient] =
          gdprPurposeRecipient[0];
      } else {
        newRecipientPurposesStateGlobal.value[idRecipient] = 4;
      }
    };

    const getNewPurposesStateGlobal = (gdprRecipientType) => {
      return newPurposesStateGlobal.value.filter((e) => {
        return (
          e.purpose.rejectable &&
          e.purpose.recipient_type === gdprRecipientType.choice
        );
      });
    };

    return {
      submitButton1,
      profileDetails,
      isOnline,
      gdprRecipientTypes,
      updateNameButton,
      updateNameFormDisplay,
      updateNameValidator,
      updateName,
      updateStatutButton,
      updateStatutFormDisplay,
      updateStatutValidator,
      updateStatut,
      updatePasswordButton,
      updatePasswordFormDisplay,
      updatePasswordValidator,
      updatePassword,
      updateDataPolicyButton,
      updateDataPolicyFormDisplay,
      updateDataPolicy,
      deleteAccountButton,
      deleteAccountFormDisplay,
      deleteAccountValidator,
      deleteAccount,
      rightsRGPD,
      parseDate: parseDate,
      openLinkAgriBest: openLinkAgriBest,
      getNewPurposesStateGlobal,
      newRecipientPurposesStateGlobal,
      setNewGdprPurposeStateGlobal,
      setNewRecipientGdprPurposeStateGlobal,
      toggleVisibility,
    };
  },
});
